import React from 'react';
import MarkdownRenderer from '../MarkDownRender/Render';

const Body = () => {
  return (
    <div>
      <MarkdownRenderer/> 
    </div>
  );
};

export default Body;